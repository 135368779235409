import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
   // getPageList:['post', '/contractOrders/page'], 
   // getOrder:['get', '/contractOrders/get'], 
   // addOrder:['postFrom', '/contractOrders/add'], 
   // updateOrder:['put', '/contractOrders/update'], 
   // delOrder:['post', '/contractOrders/delete'], 



   orderList:['post', '/api/order/page'],

   addOrder:['post', '/api/order/add'], 

   updateOrder:['post', '/api/order/update'], 

   
   delOrder:['post', '/api/order/delete'], 


   
  applySigning:['post', '/api/order/applySigning'], //申请签约


  doSigning:['post', '/api/order/doSigning'], //签约



  // payPlatformList:['post', '/api/payment-platform/list'],

  payPlatformList:['post', '/api/tenant-merchant/list'],

  


})