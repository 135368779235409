import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
   // getPageList:['post', '/historyOrders/page'], 
   // getOrder:['get', '/historyOrders/get'], 
   // delOrder:['post', '/historyOrders/delete'], 

   orderList:['post', '/api/order/page'], 

     
  summaryAmount:['post', '/api/order/summaryAmount'], 

})